// https://localizely.com/locale-code/sr-Latn-RS/
// Tranlsate to Serbian
// https://chatgpt.com/g/g-esi3vDQ3g-json-language-translator/c/670d25bb-9170-8002-90a8-400463783578
// https://v8.i18n.nuxtjs.org/getting-started/basic-usage

export default defineI18nConfig(() => ({
  legacy: false, // If you want to use vue-i18n v8.x.x, you need to set `legacy: true`.
  locale: 'sr', // locale
  defaultLocale: 'sr', // default locale
  availableLocales: ['de', 'sr'],
  messages: {
    en: {
      maintenanceModeActiveMessage:
        'Maintenance mode is active, please try again later',
      gdprMessage:
        'We use cookies to improve your experience. By using our site, you agree to our use of cookies and our privacy policy.',
      slogan: 'The best events in your town',
      featuredEvents: 'Featured events',
      startSoon: 'Starts soon',
      inYourTown: 'In your town',
      ticketsAvailable: 'Tickets available',
      searchAnything: 'Search for concerts, theater plays, sports events...',
      searchYourFavourite: 'Search for anything',
      nothingFoundforThisSearchCriteria:
        'Nothing found for this search criteria',
      latestNews: 'Latest news',
      yourFavouriteGenre: 'Your favourite genre ?',
      yourFavouriteTag: 'Your favourite tag ?',
      yourFavouriteType: 'Your favourite type ?',
      yourFavoutiteType: 'Your favourite type ?',
      eventExpired: 'Event expired',
      cart: 'Cart',
      emptyCart: 'Cart is empty',
      cartTotal: 'Total',
      cartFeeTotal: 'Processing',
      processingFeeTitle: 'Processing Fee',
      paymentGatewayFee: 'Payment Gateway Fee',
      taxFeeLabel: 'Standard VAT rate included',
      checkout: 'Checkout',
      clearCart: 'Clear Cart',
      reloadTime: 'Reload time',
      clearCartMessage:
        'Your cart is cleared, and cart is refreshed with new hold token',
      placeOrder: 'Place Order',
      yourCartIsEmptyMessage: 'Your cart is empty',
      goToEventsLink: 'Go to events',
      checkoutFirstname: 'Name',
      checkoutLastName: 'Lastname',
      checkoutEmail: 'Email',
      checkoutTelephone: 'Telephone',
      checkoutRequiredFieldsErrorMessage: 'All fields are required',
      checkoutTitle: 'Checkout',
      checkoutSuccessMessage: 'Your order is successfully placed',
      checkoutCancelMessage:
        'Order is canceled. Your cart is cleared and refreshed with new hold token. Please try again.',
      checkoutInvalidPhoneNumberFormat:
        'Invalid phone number format, please use format like +381601234567',
      checkoutPhoneNumberInfo: 'Phone number format +381601234567',
      checkoutInvalidEmailFormat: 'Invalid email format',
      checkoutPaymentGatewaySelect: 'Select payment gateway',
      checkoutBookFirstApproach:
        'We will book a seats for you immediately after you click to checkout. Note that if you dont finish the checkout process in 15 minutes, your seats will be released.',
      bookFailedAfterReturningFromGateway:
        'Book failed. Your hold token has expired. Please try again in 15 mintes, seats will be released after that',
      failedCheckoutMessage:
        'Failed to place order, order is canceled or this link is expired.',
      itemsPurchased: 'Items purchased',
      totalItemsPurchased: 'Total items purchased',
      emailMustBeUnique: 'Email already in use. Choose another on.',
      eventCountdownMessage: 'Event starts in',
      eventDaysTitle: 'Days',
      eventHoursTitle: 'Hours',
      eventMinutesTitle: 'Minutes',
      eventSecondsTitle: 'Seconds',
      tokenCountdownMessage: 'Expires in',
      tokenMinutesTitle: 'min',
      tokenSecondsTitle: 'sec',
      thankYouForOrder: 'Thank you for your order',
      thankYouForOrderSubject: 'Your order is successfully placed',
      hello: 'Hello',
      orderDetails: 'Order details',
      orderDetailsQuantity: 'Quantity',
      orderDetailsPrice: 'Price',
      orderDetailsTickets: 'Tickets',
      orderDetailsTotal: 'Total',
      orderDetailsFee: 'Processing',
      orderDetailsDisclaimer: 'If you have any questions, please contact us at',
      orderDetailsConfirmationTitle: 'Order confirmation',
      orderDetailsLabel: 'Label',
      orderDetailsRow: 'Row',
      orderDetailsSeat: 'Seat',
      orderDetailsSection: 'Section',
      yourInvoiceIsGenerated: 'Your invoice is generated',
      yourInvoiceIsGeneratedMessage:
        'Your invoice is generated. You can download it from the link below.',
      payoutInvoiceIsGenerated: 'Payout processed',
      payoutInvoiceIsGeneratedMessage:
        'Your payout is processed with success and invoice is generated.\nYou can download it from the link below.\nUsually it takes 1-2 working days for funds to be visible at you bank account.\n\nIf you have any questions, please contact us at.',
      payoutInvoiceIsGeneratedMessageCron:
        'Your payout invoice for {invoiceDate} has been generated with success.\nYou can download it from the link below.\nPlease dont forget to enter transacional number when you mark this invoice as processed/done..\n\nIf you have any questions, please contact us at.',
      iban: 'IBAN',
      taxNumber: 'Tax number',
      userSuccessfullyCreated:
        'Your account is successfully created at {siteName}. You can login with email and password here {loginUrl}',
      thankYouForUsingUs: 'Thank you for using our services',
      usernameLabel: 'Username',
      passwordLabel: 'Password',
      cookieMessage:
        'We use cookies to improve your experience. By using our site, you agree to our use of cookies.',
      cookieAccept: 'Accept',
      cookieDecline: 'Decline',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / Rnb',
        electroDj: 'Electro / DJ',
        jazzBlues: 'Jazz / Blues',
        classical: 'Classical',
        standUp: 'Stand Up',
        opera: 'Opera',
        drama: 'Drama',
        comedy: 'Comedy',
      },
      types: {
        concert: 'Concert',
        theater: 'Theater',
        sport: 'Sports',
        conference: 'Conference',
      },
      quantity: 'Quantity',
      selectNonNumericSeats: 'Select non-numeric seats',
      selectNumericSeats: 'Select numeric seats',
      whatTypeOfTicketsYouWantToBuy: 'What type of tickets you want to buy ?',
      totalSold: 'Total sold',
      footerUsefullLinksTitle: 'Usefull links',
      footerCopyrightTitle: 'All rights reserved',
      footerFollowUs: 'Follow us',
      supportAgent: 'Support',
      allEvents: 'All events',
      LoadMoreButton: 'Load more',
    },
    sr: {
      maintenanceModeActiveMessage:
        'Režim održavanja je aktivan, pokušajte ponovo kasnije',
      gdprMessage:
        'Koristimo kolačiće kako bismo poboljšali vaše iskustvo. Korišćenjem našeg sajta, slažete se sa našom upotrebom kolačića i našom politikom privatnosti.',
      slogan: 'Najbolji događaji u vašem gradu',
      featuredEvents: 'Istaknuti događaji',
      startSoon: 'Počinje uskoro',
      inYourTown: 'U vašem gradu',
      searchAnything:
        'Pretražite koncerte, pozorišne predstave, sportske događaje...',
      nothingFoundforThisSearchCriteria:
        'Ništa nije pronađeno za ovaj kriterijum pretrage',
      searchYourFavourite: 'Pretražite bilo šta',
      latestNews: 'Najnovije vesti',
      yourFavouriteGenre: 'Vaš omiljeni žanr ?',
      yourFavouriteTag: 'Vaš omiljeni tag ?',
      yourFavouriteType: 'Vaš omiljeni tip ?',
      ticketsAvailable: 'Karte dostupne',
      eventExpired: 'Događaj istekao',
      cart: 'Korpa',
      emptyCart: 'Korpa je prazna',
      cartTotal: 'Ukupno',
      cartFeeTotal: 'Trošak obrade',
      processingFeeTitle: 'Trošak obrade',
      paymentGatewayFee: 'Trošak platne institucije',
      taxFeeLabel: 'Uključena standardna stopa PDV-a',
      checkout: 'Naplata',
      clearCart: 'Očisti korpu',
      reloadTime: 'Vreme osveži',
      clearCartMessage:
        'Vaša korpa je očišćena, i korpa je osvežena sa novim tokenom',
      placeOrder: 'Naruči',
      yourCartIsEmptyMessage: 'Vaša korpa je prazna',
      goToEventsLink: 'Idi na događaje',
      checkoutFirstname: 'Ime',
      checkoutLastName: 'Prezime',
      checkoutEmail: 'Email',
      checkoutTelephone: 'Telefon',
      checkoutRequiredFieldsErrorMessage: 'Sva polja su obavezna',
      checkoutTitle: 'Naplata',
      checkoutSuccessMessage: 'Vaša narudžbina je uspešno postavljena',
      checkoutCancelMessage:
        'Narudžbina je otkazana. Vaša korpa je prazna i osvežena sa novim tokenom. Molimo vas da pokušate ponovo.',
      checkoutInvalidPhoneNumberFormat:
        'Neispravan format broja telefona, molimo vas koristite +381601234567',
      checkoutPhoneNumberInfo: 'Format broja telefona +381601234567',
      checkoutInvalidEmailFormat: 'Neispravan format emaila',
      checkoutPaymentGatewaySelect: 'Izaberite platni gateway',

      checkoutBookFirstApproach:
        'Sedišta će biti rezervisana odmah nakon što kliknete na naplatu. Imajte na umu da ako ne završite proces naplate u roku od 15 minuta, vaša sedišta će biti oslobođena.',
      bookFailedAfterReturningFromGateway:
        'Rezervacija nije uspela. Vaš token je istekao. Molimo vas pokušajte ponovo za 15 minuta, sedišta će biti oslobođena nakon toga',

      failedCheckoutMessage:
        'Narudžbina nije uspela, narudžbina je otkazana ili je link istekao.',
      itemsPurchased: 'Kupljeni artikli',
      totalItemsPurchased: 'Ukupno kupljenih artikala',
      emailMustBeUnique: 'Email već postoji. Izaberite',
      orderDetailsConfirmationTitle: 'Potvrda porudzbine',
      eventCountdownMessage: 'Događaj počinje za',
      eventDaysTitle: 'Dana',
      eventHoursTitle: 'Sati',
      eventMinutesTitle: 'Minuta',
      eventSecondsTitle: 'Sekundi',
      tokenCountdownMessage: 'Ističe za',
      tokenMinutesTitle: 'min',
      tokenSecondsTitle: 'sek',
      thankYouForOrder: 'Hvala na porudžbini',
      thankYouForOrderSubject: 'Vaša porudžbina je uspešno postavljena',
      hello: 'Zdravo',
      orderDetails: 'Detalji narudžbine',
      orderDetailsQuantity: 'Količina',
      orderDetailsPrice: 'Cena',
      orderDetailsTotal: 'Ukupno',
      orderDetailsFee: 'Trošak obrade',
      orderDetailsTickets: 'Karte',
      orderDetailsDisclaimer:
        'Ukoliko imate bilo kakvih pitanja, molimo vas da nas kontaktirate na',
      orderDetailsLabel: 'Sekcija',
      orderDetailsRow: 'Red',
      orderDetailsSeat: 'Sedište',
      orderDetailsSection: 'Sekcija',
      yourInvoiceIsGenerated: 'Vaša faktura je generisana',
      yourInvoiceIsGeneratedMessage:
        'Vaša faktura je generisana. Možete je preuzeti sa linka ispod.',
      payoutInvoiceIsGenerated: 'Isplata obrađena',
      payoutInvoiceIsGeneratedMessage:
        'Vaša isplata je uspešno obrađena i faktura je generisana.\nMožete je preuzeti sa linka ispod.\nObično je potrebno 1-2 radna dana da sredstva budu vidljiva na vašem bankovnom računu.\n\nUkoliko imate bilo kakvih pitanja, molimo vas da nas kontaktirate nas.',
      payoutInvoiceIsGeneratedMessageCron:
        'Vaša faktura za isplatu za {invoiceDate} je uspešno generisana.\nMožete je preuzeti sa linka ispod.\nNemojte zaboraviti uneti transakcioni broj kada označite ovu fakturu kao obrađenu.\n\nUkoliko imate bilo kakvih pitanja, molimo vas da nas kontaktirate nas.',
      iban: 'Žiro račun',
      taxNumber: 'PIB',
      userSuccessfullyCreated:
        'Vaš nalog je uspešno kreiran na {siteName}. Možete se prijaviti sa emailom i lozinkom ovde {loginUrl}',
      thankYouForUsingUs: 'Hvala što koristite naše usluge',
      usernameLabel: 'Korisničko ime',
      passwordLabel: 'Lozinka',
      cookieMessage:
        'Koristimo kolačiće kako bi poboljšali vaše iskustvo. Korišćenjem našeg sajta, slažete se sa našom upotrebom kolačića.',
      cookieAccept: 'Prihvati',
      cookieDecline: 'Odbij',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / Rnb',
        electroDj: 'Elektro / DJ',
        jazzBlues: 'Džez / Bluz',
        classical: 'Klasična',
        standUp: 'Stand Up',
        opera: 'Opera',
        drama: 'Drama',
        comedy: 'Komedija',
      },
      types: {
        concert: 'Koncert',
        theater: 'Pozorište',
        sport: 'Sport',
        conference: 'Konferencija',
      },
      quantity: 'Količina',
      selectNonNumericSeats: 'Stajanje',
      selectNumericSeats: 'Izaberite mesta',
      whatTypeOfTicketsYouWantToBuy: 'Kakve karte želite da kupite ?',
      totalSold: 'Ukupno prodatih',
      footerUsefullLinksTitle: 'Korisni linkovi',
      footerCopyrightTitle: 'Sva prava zadržana',
      footerFollowUs: 'Pratite nas',
      supportAgent: 'Podrška',
      allEvents: 'Svi događaji',
      LoadMoreButton: 'Učitaj više',
    },
    de: {
      maintenanceModeActiveMessage:
        'Wartungsmodus ist aktiv, bitte versuchen Sie es später erneut',
      gdprMessage:
        'Wir verwenden Cookies, um Ihr Erlebnis zu verbessern. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies und unserer Datenschutzrichtlinie zu.',
      slogan: 'Die besten Veranstaltungen in deiner Stadt',
      featuredEvents: 'Veranstaltungen im Fokus',
      startSoon: 'Beginnt bald',
      inYourTown: 'In deiner Stadt',
      ticketsAvailable: 'Tickets verfügbar',
      searchAnything:
        'Suche nach Konzerten, Theaterstücken, Sportveranstaltungen...',
      searchYourFavourite: 'Suche nach allem',
      nothingFoundforThisSearchCriteria:
        'Keine Ergebnisse für diese Suchkriterien',
      latestNews: 'Neueste Nachrichten',
      yourFavouriteGenre: 'Dein Lieblingsgenre?',
      yourFavouriteTag: 'Dein Lieblingstag?',
      yourFavouriteType: 'Dein Lieblingstyp?',
      eventExpired: 'Veranstaltung abgelaufen',
      cart: 'Warenkorb',
      emptyCart: 'Warenkorb ist leer',
      cartTotal: 'Gesamt',
      cartFeeTotal: 'Bearbeitung',
      processingFeeTitle: 'Bearbeitungsgebühr',
      paymentGatewayFee: 'Zahlungsabwicklungsgebühr',
      taxFeeLabel: 'Standard-Mehrwertsteuersatz inbegriffen',
      checkout: 'Zur Kasse',
      clearCart: 'Warenkorb leeren',
      reloadTime: 'Aktualisierungszeit',
      clearCartMessage:
        'Dein Warenkorb wurde geleert und mit einem neuen Reservierungstoken aktualisiert',
      placeOrder: 'Bestellung aufgeben',
      yourCartIsEmptyMessage: 'Dein Warenkorb ist leer',
      goToEventsLink: 'Zu den Veranstaltungen',
      checkoutFirstname: 'Vorname',
      checkoutLastName: 'Nachname',
      checkoutEmail: 'E-Mail',
      checkoutTelephone: 'Telefon',
      checkoutRequiredFieldsErrorMessage: 'Alle Felder sind erforderlich',
      checkoutTitle: 'Kasse',
      checkoutSuccessMessage: 'Deine Bestellung wurde erfolgreich aufgegeben',
      checkoutCancelMessage:
        'Bestellung storniert. Dein Warenkorb wurde geleert und mit einem neuen Reservierungstoken aktualisiert. Bitte versuche es erneut.',
      checkoutInvalidPhoneNumberFormat:
        'Ungültiges Telefonnummernformat, bitte verwende das Format wie +491601234567',
      checkoutPhoneNumberInfo: 'Telefonnummerformat +491601234567',

      checkoutInvalidEmailFormat: 'Ungültiges E-Mail-Format',
      checkoutPaymentGatewaySelect: 'Wählen Sie das Zahlungsgateway',
      checkoutBookFirstApproach:
        'Wir werden sofort nach dem Klick auf die Kasse einen Sitzplatz für Sie buchen. Bitte beachten Sie, dass wenn Sie den Bestellvorgang nicht innerhalb von 15 Minuten abschließen, Ihre Sitze freigegeben werden.',
      bookFailedAfterReturningFromGateway:
        'Buchung fehlgeschlagen. Ihr Reservierungstoken ist abgelaufen. Bitte versuchen Sie es in 15 Minuten erneut, die Sitze werden danach freigegeben',
      failedCheckoutMessage:
        'Bestellung konnte nicht aufgegeben werden, Bestellung wurde storniert oder dieser Link ist abgelaufen.',
      itemsPurchased: 'Gekaufte Artikel',
      totalItemsPurchased: 'Insgesamt gekaufte Artikel',
      emailMustBeUnique: 'E-Mail bereits in Verwendung. Wähle eine andere.',
      eventCountdownMessage: 'Die Veranstaltung beginnt in',
      eventDaysTitle: 'Tage',
      eventHoursTitle: 'Stunden',
      eventMinutesTitle: 'Minuten',
      eventSecondsTitle: 'Sekunden',
      tokenCountdownMessage: 'Läuft ab in',
      tokenMinutesTitle: 'min',
      tokenSecondsTitle: 'sek',
      thankYouForOrder: 'Danke für deine Bestellung',
      thankYouForOrderSubject: 'Deine Bestellung wurde erfolgreich aufgegeben',
      hello: 'Hallo',
      orderDetails: 'Bestelldetails',
      orderDetailsQuantity: 'Menge',
      orderDetailsPrice: 'Preis',
      orderDetailsTickets: 'Tickets',
      orderDetailsTotal: 'Gesamt',
      orderDetailsFee: 'Bearbeitung',
      orderDetailsDisclaimer:
        'Wenn du Fragen hast, kontaktiere uns bitte unter',
      orderDetailsConfirmationTitle: 'Bestellbestätigung',
      orderDetailsLabel: 'Section',
      orderDetailsRow: 'Reihe',
      orderDetailsSeat: 'Sitz',
      orderDetailsSection: 'Abschnitt',
      yourInvoiceIsGenerated: 'Deine Rechnung wurde erstellt',
      yourInvoiceIsGeneratedMessage:
        'Deine Rechnung wurde erstellt. Du kannst sie über den untenstehenden Link herunterladen.',
      payoutInvoiceIsGenerated: 'Auszahlung bearbeitet',
      payoutInvoiceIsGeneratedMessage:
        'Deine Auszahlung wurde erfolgreich bearbeitet und die Rechnung wurde erstellt.\nDu kannst sie über den untenstehenden Link herunterladen.\nNormalerweise dauert es 1-2 Werktage, bis die Gelder auf deinem Bankkonto sichtbar sind.\n\nWenn du Fragen hast, kontaktiere uns bitte.',
      payoutInvoiceIsGeneratedMessageCron:
        'Deine Auszahlungsrechnung für {invoiceDate} wurde erfolgreich erstellt.\nDu kannst sie über den untenstehenden Link herunterladen.\nBitte vergiss nicht, die Transaktionsnummer einzugeben, wenn du diese Rechnung als bearbeitet/erledigt markierst.\n\nWenn du Fragen hast, kontaktiere uns bitte.',
      iban: 'IBAN',
      taxNumber: 'Steuernummer',
      userSuccessfullyCreated:
        'Dein Konto wurde erfolgreich bei {siteName} erstellt. Du kannst dich hier mit E-Mail und Passwort anmelden: {loginUrl}',
      thankYouForUsingUs: 'Danke, dass du unseren Service nutzt',
      usernameLabel: 'Benutzername',
      passwordLabel: 'Passwort',
      cookieMessage:
        'Wir verwenden Cookies, um dein Erlebnis zu verbessern. Durch die Nutzung unserer Seite stimmst du der Verwendung von Cookies zu.',
      cookieAccept: 'Akzeptieren',
      cookieDecline: 'Ablehnen',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / Rnb',
        electroDj: 'Electro / DJ',
        jazzBlues: 'Jazz / Blues',
        classical: 'Klassik',
        standUp: 'Stand Up',
        opera: 'Oper',
        drama: 'Drama',
        comedy: 'Komödie',
      },
      types: {
        concert: 'Konzert',
        theater: 'Theater',
        sport: 'Sport',
        conference: 'Konferenz',
      },
      quantity: 'Menge',
      selectNonNumericSeats: 'Stehplatz',
      selectNumericSeats: 'Sitzplatz auswählen',
      whatTypeOfTicketsYouWantToBuy:
        'Welche Art von Tickets möchtest du kaufen?',
      totalSold: 'Gesamt verkauft',
      footerUsefullLinksTitle: 'Nützliche Links',
      footerCopyrightTitle: 'Alle Rechte vorbehalten',
      footerFollowUs: 'Folge uns',
      supportAgent: 'Support',
      allEvents: 'Alle Veranstaltungen',
      LoadMoreButton: 'Mehr laden',
    },
    es: {
      maintenanceModeActiveMessage:
        'El modo de mantenimiento está activo, por favor inténtalo de nuevo más tarde',
      gdprMessage:
        'Utilizamos cookies para mejorar tu experiencia. Al usar nuestro sitio, aceptas el uso de cookies y nuestra política de privacidad.',
      slogan: 'Los mejores eventos en tu ciudad',
      featuredEvents: 'Eventos destacados',
      startSoon: 'Empieza pronto',
      inYourTown: 'En tu ciudad',
      ticketsAvailable: 'Entradas disponibles',
      searchAnything:
        'Buscar conciertos, obras de teatro, eventos deportivos...',
      searchYourFavourite: 'Buscar cualquier cosa',
      nothingFoundforThisSearchCriteria:
        'No se encontró nada para estos criterios de búsqueda',
      latestNews: 'Últimas noticias',
      yourFavouriteGenre: '¿Tu género favorito?',
      yourFavouriteTag: '¿Tu etiqueta favorita?',
      yourFavouriteType: '¿Tu tipo favorito?',
      eventExpired: 'Evento expirado',
      cart: 'Carrito',
      emptyCart: 'El carrito está vacío',
      cartTotal: 'Total',
      cartFeeTotal: 'Procesamiento',
      processingFeeTitle: 'Tarifa de procesamiento',
      paymentGatewayFee: 'Tarifa de la pasarela de pago',
      taxFeeLabel: 'Tasa de IVA estándar incluida',
      checkout: 'Pagar',
      clearCart: 'Vaciar carrito',
      reloadTime: 'Tiempo de recarga',
      clearCartMessage:
        'Tu carrito ha sido vaciado y actualizado con un nuevo token de retención',
      placeOrder: 'Realizar pedido',
      yourCartIsEmptyMessage: 'Tu carrito está vacío',
      goToEventsLink: 'Ir a eventos',
      checkoutFirstname: 'Nombre',
      checkoutLastName: 'Apellido',
      checkoutEmail: 'Correo electrónico',
      checkoutTelephone: 'Teléfono',
      checkoutRequiredFieldsErrorMessage: 'Todos los campos son obligatorios',
      checkoutTitle: 'Pagar',
      checkoutSuccessMessage: 'Tu pedido se ha realizado con éxito',
      checkoutCancelMessage:
        'Pedido cancelado. Tu carrito ha sido vaciado y actualizado con un nuevo token de retención. Por favor, inténtalo de nuevo.',
      checkoutInvalidPhoneNumberFormat:
        'Formato de número de teléfono no válido, utiliza el formato +34601234567',
      checkoutPhoneNumberInfo: 'Formato de número de teléfono +34601234567',
      checkoutInvalidEmailFormat: 'Formato de correo electrónico no válido',
      checkoutPaymentGatewaySelect: 'Selecciona la pasarela de pago',
      checkoutBookFirstApproach:
        'Reservaremos un asiento para ti inmediatamente después de hacer clic en pagar. Ten en cuenta que si no completas el proceso de pago en 15 minutos, tus asientos se liberarán.',
      bookFailedAfterReturningFromGateway:
        'Reserva fallida. Tu token de retención ha expirado. Por favor, inténtalo de nuevo en 15 minutos, los asientos se liberarán después de eso',
      failedCheckoutMessage:
        'No se pudo realizar el pedido, el pedido se canceló o este enlace ha expirado.',
      itemsPurchased: 'Artículos comprados',
      totalItemsPurchased: 'Total de artículos comprados',
      emailMustBeUnique: 'El correo electrónico ya está en uso. Elige otro.',
      eventCountdownMessage: 'El evento empieza en',
      eventDaysTitle: 'Días',
      eventHoursTitle: 'Horas',
      eventMinutesTitle: 'Minutos',
      eventSecondsTitle: 'Segundos',
      tokenCountdownMessage: 'Expira en',
      tokenMinutesTitle: 'min',
      tokenSecondsTitle: 'seg',
      thankYouForOrder: 'Gracias por tu pedido',
      thankYouForOrderSubject: 'Tu pedido se ha realizado con éxito',
      hello: 'Hola',
      orderDetails: 'Detalles del pedido',
      orderDetailsQuantity: 'Cantidad',
      orderDetailsPrice: 'Precio',
      orderDetailsTickets: 'Entradas',
      orderDetailsTotal: 'Total',
      orderDetailsFee: 'Procesamiento',
      orderDetailsDisclaimer: 'Si tienes alguna pregunta, contáctanos en',
      orderDetailsConfirmationTitle: 'Confirmación del pedido',
      orderDetailsLabel: 'Etiqueta',
      orderDetailsRow: 'Fila',
      orderDetailsSeat: 'Asiento',
      orderDetailsSection: 'Sección',
      yourInvoiceIsGenerated: 'Tu factura ha sido generada',
      yourInvoiceIsGeneratedMessage:
        'Tu factura ha sido generada. Puedes descargarla desde el enlace de abajo.',
      payoutInvoiceIsGenerated: 'Pago procesado',
      payoutInvoiceIsGeneratedMessage:
        'Tu pago ha sido procesado con éxito y la factura ha sido generada.\nPuedes descargarla desde el enlace de abajo.\nNormalmente, los fondos estarán disponibles en tu cuenta bancaria en 1-2 días hábiles.\n\nSi tienes alguna pregunta, contáctanos.',
      payoutInvoiceIsGeneratedMessageCron:
        'Tu factura de pago para {invoiceDate} ha sido generada con éxito.\nPuedes descargarla desde el enlace de abajo.\nNo olvides ingresar el número de transacción cuando marques esta factura como procesada/hecha.\n\nSi tienes alguna pregunta, contáctanos.',
      iban: 'IBAN',
      taxNumber: 'Número de impuesto',
      userSuccessfullyCreated:
        'Tu cuenta ha sido creada con éxito en {siteName}. Puedes iniciar sesión con correo electrónico y contraseña aquí: {loginUrl}',
      thankYouForUsingUs: 'Gracias por utilizar nuestros servicios',
      usernameLabel: 'Nombre de usuario',
      passwordLabel: 'Contraseña',
      cookieMessage:
        'Utilizamos cookies para mejorar tu experiencia. Al usar nuestro sitio, aceptas el uso de cookies.',
      cookieAccept: 'Aceptar',
      cookieDecline: 'Rechazar',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / Rnb',
        electroDj: 'Electro / DJ',
        jazzBlues: 'Jazz / Blues',
        classical: 'Clásica',
        standUp: 'Monólogos',
        opera: 'Ópera',
        drama: 'Drama',
        comedy: 'Comedia',
      },
      types: {
        concert: 'Concierto',
        theater: 'Teatro',
        sport: 'Deportes',
        conference: 'Conferencia',
      },
      quantity: 'Cantidad',
      selectNonNumericSeats: 'Seleccionar asientos no numéricos',
      selectNumericSeats: 'Seleccionar asientos numéricos',
      whatTypeOfTicketsYouWantToBuy: '¿Qué tipo de entradas quieres comprar?',
      totalSold: 'Total vendido',
      footerUsefullLinksTitle: 'Enlaces útiles',
      footerCopyrightTitle: 'Todos los derechos reservados',
      footerFollowUs: 'Síguenos',
      supportAgent: 'Soporte',
      allEvents: 'Todos los eventos',
      LoadMoreButton: 'Cargar más',
    },
    nl: {
      maintenanceModeActiveMessage:
        'Onderhoudsmodus is actief, probeer het later opnieuw',
      gdprMessage:
        'We gebru  cookies om je ervaring te verbeteren. Door onze site te gebruiken, ga je akkoord met ons gebruik van cookies en ons privacybeleid.',
      slogan: 'De beste evenementen in jouw stad',
      featuredEvents: 'Uitgelichte evenementen',
      startSoon: 'Begint binnenkort',
      inYourTown: 'In jouw stad',
      ticketsAvailable: 'Tickets beschikbaar',
      searchAnything:
        'Zoek naar concerten, theaterstukken, sportevenementen...',
      searchYourFavourite: 'Zoek naar alles',
      nothingFoundforThisSearchCriteria:
        'Niets gevonden voor deze zoekcriteria',
      latestNews: 'Laatste nieuws',
      yourFavouriteGenre: 'Jouw favoriete genre?',
      yourFavouriteTag: 'Jouw favoriete tag?',
      yourFavouriteType: 'Jouw favoriete type?',
      eventExpired: 'Evenement verlopen',
      cart: 'Winkelwagen',
      emptyCart: 'Winkelwagen is leeg',
      cartTotal: 'Totaal',
      cartFeeTotal: 'Verwerking',
      processingFeeTitle: 'Verwerkingskosten',
      paymentGatewayFee: 'Betalingsgatewaykosten',
      taxFeeLabel: 'Standaard btw-tarief inbegrepen',
      checkout: 'Afrekenen',
      clearCart: 'Winkelwagen leegmaken',
      reloadTime: 'Vernieuwingstijd',
      clearCartMessage:
        'Je winkelwagen is geleegd en vernieuwd met een nieuwe reserveringstoken',
      placeOrder: 'Bestelling plaatsen',
      yourCartIsEmptyMessage: 'Je winkelwagen is leeg',
      goToEventsLink: 'Ga naar evenementen',
      checkoutFirstname: 'Voornaam',
      checkoutLastName: 'Achternaam',
      checkoutEmail: 'E-mailadres',
      checkoutTelephone: 'Telefoon',
      checkoutRequiredFieldsErrorMessage: 'Alle velden zijn verplicht',
      checkoutTitle: 'Afrekenen',
      checkoutSuccessMessage: 'Je bestelling is succesvol geplaatst',
      checkoutCancelMessage:
        'Bestelling geannuleerd. Je winkelwagen is geleegd en vernieuwd met een nieuwe reserveringstoken. Probeer het opnieuw.',
      checkoutInvalidPhoneNumberFormat:
        'Ongeldig telefoonnummerformaat, gebruik het formaat zoals +31601234567',
      checkoutPhoneNumberInfo: 'Telefoonnummerformaat +31601234567',
      checkoutInvalidEmailFormat: 'Ongeldig e-mailformaat',
      checkoutPaymentGatewaySelect: 'Selecteer betalingsgateway',
      checkoutBookFirstApproach:
        'We zullen direct na het klikken op afrekenen een stoel voor je reserveren. Houd er rekening mee dat als je het afrekenproces niet binnen 15 minuten voltooit, je stoelen worden vrijgegeven.',
      bookFailedAfterReturningFromGateway:
        'Boekingsfout. Je reserveringstoken is verlopen. Probeer het over 15 minuten opnieuw, de stoelen worden daarna vrijgegeven',
      failedCheckoutMessage:
        'Bestelling kon niet worden geplaatst, bestelling is geannuleerd of deze link is verlopen.',
      itemsPurchased: 'Gekochte artikelen',
      totalItemsPurchased: 'Totaal gekochte artikelen',
      emailMustBeUnique: 'E-mailadres is al in gebruik. Kies een ander.',
      eventCountdownMessage: 'Het evenement begint in',
      eventDaysTitle: 'Dagen',
      eventHoursTitle: 'Uren',
      eventMinutesTitle: 'Minuten',
      eventSecondsTitle: 'Seconden',
      tokenCountdownMessage: 'Verloopt in',
      tokenMinutesTitle: 'min',
      tokenSecondsTitle: 'sec',
      thankYouForOrder: 'Bedankt voor je bestelling',
      thankYouForOrderSubject: 'Je bestelling is succesvol geplaatst',
      hello: 'Hallo',
      orderDetails: 'Bestelgegevens',
      orderDetailsQuantity: 'Aantal',
      orderDetailsPrice: 'Prijs',
      orderDetailsTickets: 'Tickets',
      orderDetailsTotal: 'Totaal',
      orderDetailsFee: 'Verwerking',
      orderDetailsDisclaimer:
        'Als je vragen hebt, neem dan contact met ons op via',
      orderDetailsConfirmationTitle: 'Bestelbevestiging',
      orderDetailsLabel: 'Label',
      orderDetailsRow: 'Rij',
      orderDetailsSeat: 'Stoel',
      orderDetailsSection: 'Sectie',
      yourInvoiceIsGenerated: 'Je factuur is gegenereerd',
      yourInvoiceIsGeneratedMessage:
        'Je factuur is gegenereerd. Je kunt deze downloaden via de onderstaande link.',
      payoutInvoiceIsGenerated: 'Uitbetaling verwerkt',
      payoutInvoiceIsGeneratedMessage:
        'Je uitbetaling is succesvol verwerkt en de factuur is gegenereerd.\nJe kunt deze downloaden via de onderstaande link.\nMeestal duurt het 1-2 werkdagen voordat de middelen zichtbaar zijn op je bankrekening.\n\nAls je vragen hebt, neem dan contact met ons op.',
      payoutInvoiceIsGeneratedMessageCron:
        'Je uitbetalingsfactuur voor {invoiceDate} is succesvol gegenereerd.\nJe kunt deze downloaden via de onderstaande link.\nVergeet niet het transactienummer in te voeren wanneer je deze factuur als verwerkt/gereed markeert.\n\nAls je vragen hebt, neem dan contact met ons op.',
      iban: 'IBAN',
      taxNumber: 'Belastingnummer',
      userSuccessfullyCreated:
        'Je account is succesvol aangemaakt op {siteName}. Je kunt hier inloggen met je e-mailadres en wachtwoord: {loginUrl}',
      thankYouForUsingUs: 'Bedankt dat je gebruik maakt van onze diensten',
      usernameLabel: 'Gebruikersnaam',
      passwordLabel: 'Wachtwoord',
      cookieMessage:
        'Wij gebruiken cookies om je ervaring te verbeteren. Door onze site te gebruiken, ga je akkoord met ons gebruik van cookies.',
      cookieAccept: 'Accepteren',
      cookieDecline: 'Weigeren',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / Rnb',
        electroDj: 'Electro / DJ',
        jazzBlues: 'Jazz / Blues',
        classical: 'Klassiek',
        standUp: 'Stand-up',
        opera: 'Opera',
        drama: 'Drama',
        comedy: 'Komedie',
      },
      types: {
        concert: 'Concert',
        theater: 'Theater',
        sport: 'Sport',
        conference: 'Conferentie',
      },
      quantity: 'Aantal',
      selectNonNumericSeats: 'Selecteer niet-genummerde stoelen',
      selectNumericSeats: 'Selecteer genummerde stoelen',
      whatTypeOfTicketsYouWantToBuy: 'Welke soort tickets wil je kopen?',
      totalSold: 'Totaal verkocht',
      footerUsefullLinksTitle: 'Handige links',
      footerCopyrightTitle: 'Alle rechten voorbehouden',
      footerFollowUs: 'Volg ons',
      supportAgent: 'Ondersteuning',
      allEvents: 'Alle evenementen',
      LoadMoreButton: 'Meer laden',
    },
    ru: {
      maintenanceModeActiveMessage:
        'Режим обслуживания активен, пожалуйста, попробуйте позже',
      gdprMessage:
        'Мы используем файлы cookie, чтобы улучшить ваш опыт. Используя наш сайт, вы соглашаетесь с использованием файлов cookie и нашей политикой конфиденциальности.',
      slogan: 'Лучшие события в вашем городе',
      featuredEvents: 'Избранные события',
      startSoon: 'Скоро начнется',
      inYourTown: 'В вашем городе',
      ticketsAvailable: 'Доступны билеты',
      searchAnything: 'Поиск концертов, спектаклей, спортивных событий...',
      searchYourFavourite: 'Ищите что угодно',
      nothingFoundforThisSearchCriteria: 'По данному запросу ничего не найдено',
      latestNews: 'Последние новости',
      yourFavouriteGenre: 'Ваш любимый жанр?',
      yourFavouriteTag: 'Ваш любимый тег?',
      yourFavouriteType: 'Ваш любимый тип?',
      eventExpired: 'Событие завершено',
      cart: 'Корзина',
      emptyCart: 'Корзина пуста',
      cartTotal: 'Итого',
      cartFeeTotal: 'Обработка',
      processingFeeTitle: 'Плата за обработку',
      paymentGatewayFee: 'Плата за платёжный шлюз',
      taxFeeLabel: 'Включен стандартный НДС',
      checkout: 'Оформить заказ',
      clearCart: 'Очистить корзину',
      reloadTime: 'Время обновления',
      clearCartMessage:
        'Ваша корзина очищена и обновлена с новым токеном удержания',
      placeOrder: 'Разместить заказ',
      yourCartIsEmptyMessage: 'Ваша корзина пуста',
      goToEventsLink: 'Перейти к событиям',
      checkoutFirstname: 'Имя',
      checkoutLastName: 'Фамилия',
      checkoutEmail: 'Электронная почта',
      checkoutTelephone: 'Телефон',
      checkoutRequiredFieldsErrorMessage: 'Все поля обязательны для заполнения',
      checkoutTitle: 'Оформление заказа',
      checkoutSuccessMessage: 'Ваш заказ успешно размещен',
      checkoutCancelMessage:
        'Заказ отменен. Ваша корзина очищена и обновлена с новым токеном удержания. Пожалуйста, попробуйте снова.',
      checkoutInvalidPhoneNumberFormat:
        'Неверный формат номера телефона, пожалуйста, используйте формат +79851234567',
      checkoutPhoneNumberInfo: 'Формат номера телефона +79851234567',
      checkoutInvalidEmailFormat: 'Неверный формат электронной почты',
      checkoutPaymentGatewaySelect: 'Выберите платежный шлюз',
      checkoutBookFirstApproach:
        'Мы забронируем место для вас сразу после нажатия на кнопку оплаты. Пожалуйста, обратите внимание, что если вы не завершите процесс оплаты в течение 15 минут, ваши места будут освобождены.',
      bookFailedAfterReturningFromGateway:
        'Ошибка бронирования. Ваш токен удержания истек. Пожалуйста, попробуйте снова через 15 минут, места будут освобождены после этого',
      failedCheckoutMessage:
        'Заказ не может быть размещен, заказ отменен или этот ссылка устарела.',
      itemsPurchased: 'Приобретенные товары',
      totalItemsPurchased: 'Общее количество купленных товаров',
      emailMustBeUnique: 'Электронная почта уже используется. Выберите другую.',
      eventCountdownMessage: 'Событие начнется через',
      eventDaysTitle: 'Дней',
      eventHoursTitle: 'Часов',
      eventMinutesTitle: 'Минут',
      eventSecondsTitle: 'Секунд',
      tokenCountdownMessage: 'Истекает через',
      tokenMinutesTitle: 'Мин',
      tokenSecondsTitle: 'Сек',
      thankYouForOrder: 'Спасибо за ваш заказ',
      thankYouForOrderSubject: 'Ваш заказ успешно размещен',
      hello: 'Здравствуйте',
      orderDetails: 'Детали заказа',
      orderDetailsQuantity: 'Количество',
      orderDetailsPrice: 'Цена',
      orderDetailsTickets: 'Билеты',
      orderDetailsTotal: 'Итого',
      orderDetailsFee: 'Обработка',
      orderDetailsDisclaimer:
        'Если у вас есть вопросы, пожалуйста, свяжитесь с нами по',
      orderDetailsConfirmationTitle: 'Подтверждение заказа',
      orderDetailsLabel: 'Метка',
      orderDetailsRow: 'Ряд',
      orderDetailsSeat: 'Место',
      orderDetailsSection: 'Секция',
      yourInvoiceIsGenerated: 'Ваш счет сформирован',
      yourInvoiceIsGeneratedMessage:
        'Ваш счет сформирован. Вы можете скачать его по ссылке ниже.',
      payoutInvoiceIsGenerated: 'Выплата обработана',
      payoutInvoiceIsGeneratedMessage:
        'Ваша выплата успешно обработана, и счет был сформирован.\nВы можете скачать его по ссылке ниже.\nОбычно перевод занимает 1-2 рабочих дня для отображения на вашем банковском счете.\n\nЕсли у вас есть вопросы, пожалуйста, свяжитесь с нами.',
      payoutInvoiceIsGeneratedMessageCron:
        'Ваш счет за выплату на {invoiceDate} был успешно сформирован.\nВы можете скачать его по ссылке ниже.\nНе забудьте ввести номер транзакции, когда отметите этот счет как обработанный/выполненный.\n\nЕсли у вас есть вопросы, пожалуйста, свяжитесь с нами.',
      iban: 'IBAN',
      taxNumber: 'Налоговый номер',
      userSuccessfullyCreated:
        'Ваша учетная запись успешно создана на {siteName}. Вы можете войти с помощью электронной почты и пароля здесь: {loginUrl}',
      thankYouForUsingUs: 'Спасибо, что воспользовались нашими услугами',
      usernameLabel: 'Имя пользователя',
      passwordLabel: 'Пароль',
      cookieMessage:
        'Мы используем файлы cookie для улучшения вашего опыта. Используя наш сайт, вы соглашаетесь с использованием файлов cookie.',
      cookieAccept: 'Принять',
      cookieDecline: 'Отклонить',
      genres: {
        popRock: 'Поп / Рок',
        rapRnb: 'Рэп / РнБ',
        electroDj: 'Электро / Диджей',
        jazzBlues: 'Джаз / Блюз',
        classical: 'Классика',
        standUp: 'Стендап',
        opera: 'Опера',
        drama: 'Драма',
        comedy: 'Комедия',
      },
      types: {
        concert: 'Концерт',
        theater: 'Театр',
        sport: 'Спорт',
        conference: 'Конференция',
      },
      quantity: 'Количество',
      selectNonNumericSeats: 'Выберите не числовые места',
      selectNumericSeats: 'Выберите числовые места',
      whatTypeOfTicketsYouWantToBuy: 'Какой тип билетов вы хотите купить?',
      totalSold: 'Всего продано',
      footerUsefullLinksTitle: 'Полезные ссылки',
      footerCopyrightTitle: 'All rights reserved',
      footerFollowUs: 'Подписывайтесь на нас',
      supportAgent: 'Поддержка',
      allEvents: 'Все события',
      LoadMoreButton: 'Загрузить еще',
    },
    se: {
      maintenanceModeActiveMessage:
        'Underhållsläge är aktivt, försök igen senare',
      gdprMessage:
        'Vi använder cookies för att förbättra din upplevelse. Genom att använda vår webbplats godkänner du användningen av cookies och vår integritetspolicy.',
      slogan: 'De bästa evenemangen i din stad',
      featuredEvents: 'Utvalda evenemang',
      startSoon: 'Börjar snart',
      inYourTown: 'I din stad',
      ticketsAvailable: 'Biljetter tillgängliga',
      searchAnything: 'Sök efter konserter, teaterpjäser, sportevenemang...',
      searchYourFavourite: 'Sök vad som helst',
      nothingFoundforThisSearchCriteria:
        'Inget hittades för dessa sökkriterier',
      latestNews: 'Senaste nytt',
      yourFavouriteGenre: 'Din favoritgenre?',
      yourFavouriteTag: 'Din favoritetikett?',
      yourFavouriteType: 'Din favorittyp?',
      eventExpired: 'Evenemanget har gått ut',
      cart: 'Kundvagn',
      emptyCart: 'Kundvagnen är tom',
      cartTotal: 'Totalt',
      cartFeeTotal: 'Behandling',
      processingFeeTitle: 'Behandlingsavgift',
      paymentGatewayFee: 'Betalvägavgift',
      taxFeeLabel: 'Standardmoms ingår',
      checkout: 'Till kassan',
      clearCart: 'Töm kundvagn',
      reloadTime: 'Uppdateringstid',
      clearCartMessage:
        'Din kundvagn har tömts och uppdaterats med en ny reserveringstoken',
      placeOrder: 'Lägg beställning',
      yourCartIsEmptyMessage: 'Din kundvagn är tom',
      goToEventsLink: 'Gå till evenemang',
      checkoutFirstname: 'Förnamn',
      checkoutLastName: 'Efternamn',
      checkoutEmail: 'E-post',
      checkoutTelephone: 'Telefon',
      checkoutRequiredFieldsErrorMessage: 'Alla fält är obligatoriska',
      checkoutTitle: 'Kassa',
      checkoutSuccessMessage: 'Din beställning har lagts framgångsrikt',
      checkoutCancelMessage:
        'Beställningen har avbrutits. Din kundvagn har tömts och uppdaterats med en ny reserveringstoken. Försök igen.',
      checkoutInvalidPhoneNumberFormat:
        'Ogiltigt telefonnummerformat, använd format som +46701234567',
      checkoutPhoneNumberInfo: 'Telefonnummerformat +46701234567',
      checkoutInvalidEmailFormat: 'Ogiltigt e-postformat',
      checkoutPaymentGatewaySelect: 'Välj betalväg',
      checkoutBookFirstApproach:
        'Vi kommer att boka en plats åt dig omedelbart efter att du klickat på kassan. Observera att om du inte slutför betalningsprocessen inom 15 minuter kommer dina platser att släppas.',
      bookFailedAfterReturningFromGateway:
        'Bokningsfel. Din reserveringstoken har gått ut. Försök igen om 15 minuter, platserna släpps efter det',
      failedCheckoutMessage:
        'Beställningen kunde inte slutföras, beställningen har avbrutits eller denna länk har gått ut.',
      itemsPurchased: 'Köpta artiklar',
      totalItemsPurchased: 'Totalt antal köpta artiklar',
      emailMustBeUnique: 'E-postadressen används redan. Välj en annan.',
      eventCountdownMessage: 'Evenemanget börjar om',
      eventDaysTitle: 'Dagar',
      eventHoursTitle: 'Timmar',
      eventMinutesTitle: 'Minuter',
      eventSecondsTitle: 'Sekunder',
      tokenCountdownMessage: 'Löper ut om',
      tokenMinutesTitle: 'Min',
      tokenSecondsTitle: 'Sek',
      thankYouForOrder: 'Tack för din beställning',
      thankYouForOrderSubject: 'Din beställning har genomförts framgångsrikt',
      hello: 'Hej',
      orderDetails: 'Beställningsdetaljer',
      orderDetailsQuantity: 'Antal',
      orderDetailsPrice: 'Pris',
      orderDetailsTickets: 'Biljetter',
      orderDetailsTotal: 'Totalt',
      orderDetailsFee: 'Behandling',
      orderDetailsDisclaimer: 'Om du har några frågor, kontakta oss på',
      orderDetailsConfirmationTitle: 'Beställningsbekräftelse',
      orderDetailsLabel: 'Etikett',
      orderDetailsRow: 'Rad',
      orderDetailsSeat: 'Säte',
      orderDetailsSection: 'Sektion',
      yourInvoiceIsGenerated: 'Din faktura har skapats',
      yourInvoiceIsGeneratedMessage:
        'Din faktura har skapats. Du kan ladda ner den från länken nedan.',
      payoutInvoiceIsGenerated: 'Utbetalning bearbetad',
      payoutInvoiceIsGeneratedMessage:
        'Din utbetalning har framgångsrikt bearbetats och fakturan har skapats.\nDu kan ladda ner den från länken nedan.\nVanligtvis tar det 1-2 arbetsdagar innan pengarna syns på ditt bankkonto.\n\nOm du har några frågor, kontakta oss.',
      payoutInvoiceIsGeneratedMessageCron:
        'Din utbetalningsfaktura för {invoiceDate} har skapats framgångsrikt.\nDu kan ladda ner den från länken nedan.\nGlöm inte att ange transaktionsnumret när du markerar denna faktura som bearbetad/gjord.\n\nOm du har några frågor, kontakta oss.',
      iban: 'IBAN',
      taxNumber: 'Skattenummer',
      userSuccessfullyCreated:
        'Ditt konto har skapats framgångsrikt på {siteName}. Du kan logga in med e-post och lösenord här: {loginUrl}',
      thankYouForUsingUs: 'Tack för att du använder våra tjänster',
      usernameLabel: 'Användarnamn',
      passwordLabel: 'Lösenord',
      cookieMessage:
        'Vi använder cookies för att förbättra din upplevelse. Genom att använda vår webbplats godkänner du vår användning av cookies.',
      cookieAccept: 'Acceptera',
      cookieDecline: 'Avböj',
      genres: {
        popRock: 'Pop / Rock',
        rapRnb: 'Rap / RnB',
        electroDj: 'Elektro / DJ',
        jazzBlues: 'Jazz / Blues',
        classical: 'Klassisk',
        standUp: 'Stand-up',
        opera: 'Opera',
        drama: 'Drama',
        comedy: 'Komedi',
      },
      types: {
        concert: 'Konsert',
        theater: 'Teater',
        sport: 'Sport',
        conference: 'Konferens',
      },
      quantity: 'Antal',
      selectNonNumericSeats: 'Välj icke-numeriska platser',
      selectNumericSeats: 'Välj numeriska platser',
      whatTypeOfTicketsYouWantToBuy: 'Vilken typ av biljetter vill du köpa?',
      totalSold: 'Totalt sålda',
      footerUsefullLinksTitle: 'Användbara länkar',
      footerCopyrightTitle: 'Alla rättigheter förbehållna',
      footerFollowUs: 'Följ oss',
      supportAgent: 'Support',
      allEvents: 'Alla evenemang',
      LoadMoreButton: 'Ladda mer',
    },
  },
}))
